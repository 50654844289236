<template>
  <validation-observer ref="simpleRules">
    <div class="col-12">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50"
        @click="$router.go(-1)"
      >
        <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
      </b-button>
      <b-button
        class="btn btn-gradient-success mb-1 ml-25 mr-50"
        @click="Submit()"
      >
        <i class="far fa-save mr-50" /> {{ $t('saveData') }}
      </b-button>
      <div>
        <div class="card fixed-card">
          <div class="d-flex justify-content-between align-items-center px-2">
            <div>
              <br>
              <h3
                class="font-weight-bolder"
              >
                {{ $t('key-83') }}
              </h3>
            </div>
          </div>
          <hr>
          <b-form-group
            label-for="h-parcel-code"
            label-cols="2"
            label-cols-lg="2"
            label-cols-md="2"
          >
            <template v-slot:label>
              <span class="text-danger">*</span> {{ $t('title') }}
            </template>
            <validation-provider
              #default="{ errors }"
              name="question-type"
              rules="required"
            >
              <b-form-input
                v-model="items.postName"
                class="col-8"
                :state="errors.length > 0 ? false:null"
              />
              <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-parcel-code"
            label-cols="2"
            label-cols-lg="2"
            label-cols-md="2"
          >
            <template v-slot:label>
              {{ $t('coverImage') }}
            </template>
            <div
              v-if="items.file_file"
              class="preview-image-container mr-25 d-flex justify align-items-center"
            >
              <img
                :src="GetImg('Notice_file',items.file_file)"
                alt="Preview"
                width="100px"
                height="50px"
                @click="showPicture(items.file_file)"
              >
              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
              <div class="preview-image-overlays p-0">
                <i
                  class="fas fa-times-circle cursor-pointer"
                  @click="removeImage(items.file_file = null)"
                />
              </div>
            </div>
            <div
              v-else
              class="input-file-container cursor-pointer"
            >
              <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
              <input
                id="img-file"
                ref="fileInput"
                type="file"
                class="input-file"
                accept="image/*"
                multiple
                @change="handleFileChanges($event)"
              >
            </div>
          </b-form-group>

          <b-form-group
            label-for="h-parcel-code"
            label-cols="2"
            label-cols-lg="2"
            label-cols-md="2"
          >
            <template v-slot:label>
              {{ $t('activate') }}
            </template>
            <b-form-checkbox
              v-model="items.active"
              class="custom-control-success mt-50"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <!-- <quill-editor
            v-model="items.content"
            :options="snowOption"
            class="p-2 fixed-edit"
          /> -->

          <quill-editor
            ref="quillEditor"
            v-model="items.content"
            class="p-2"
            :options="editorOption"
          >
            <div
              id="toolbar"
              slot="toolbar"
            >
              <span class="ql-formats">
                <select class="ql-font" />
                <select class="ql-size" />
              </span>
              <span class="ql-formats">
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-strike" />
              </span>
              <span class="ql-formats">
                <select class="ql-color" />
                <select class="ql-background" />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-script"
                  value="sub"
                />
                <button
                  class="ql-script"
                  value="super"
                />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-header"
                  value="1"
                />
                <button
                  class="ql-header"
                  value="2"
                />
                <button class="ql-blockquote" />
                <button class="ql-code-block" />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-list"
                  value="ordered"
                />
                <button
                  class="ql-list"
                  value="bullet"
                />
                <button
                  class="ql-indent"
                  value="-1"
                />
                <button
                  class="ql-indent"
                  value="+1"
                />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-direction"
                  value="rtl"
                />
                <select class="ql-align" />
              </span>
              <span class="ql-formats">
                <button class="ql-link" />
                <button class="ql-image" />
                <button class="ql-video" />
                <button class="ql-formula" />
              </span>
              <span class="ql-formats">
                <button class="ql-clean" />
              </span>
              <b-dropdown
                text="😃"
                variant="light"
              >
                <VEmojiPicker
                  @select="selectEmoji"
                />
              </b-dropdown>
            </div>
          </quill-editor>

        </div>
      </div>
    </div></validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BDropdown,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { required } from '@validations'
// eslint-disable-next-line
import { VEmojiPicker } from 'v-emoji-picker'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    quillEditor,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VEmojiPicker,
    BDropdown,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      content: '',
      postName: '',
      id: this.$route.params.id,
      items: [],
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'กรอกข้อความ...',
      },
    }
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleFileChanges(event) {
      const filescus = event.target.files
      console.debug(filescus)
      const reader = new FileReader()
      const filepath = filescus[0]
      reader.onload = e => {
        // console.log(e.target.result)
        // console.log(e.target.result)
        this.items.file_file = e.target.result
        // console.log(this.filecusimg)
        // e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    selectEmoji(dataEmoji) {
      const cursor = this.editor.getSelection()
      if (cursor) {
        if (cursor.length <= 0) {
          this.editor.insertText(this.editor.getSelection(), dataEmoji.data)
        } else {
          this.editor.setText(dataEmoji.data)
        }
      } else {
        const range = this.editor.getText().length - 1
        this.editor.insertText(range, dataEmoji.data)
      }
    },
    async getData() {
      try {
        const params = {
          id: this.id,
        }
        const { data: res } = await this.$http.get('/Notice/getDataOne', { params })
        this.items = res
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            content: this.items.content,
            postName: this.items.postName,
            file_file: this.items.file_file,
            active: this.items.active,
          }
          this.$http.post('/Notice/StoreNotice/data', obj)
            .then(() => {
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.$router.push({ name: 'admin-notice' })
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Notice_file', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-edit{
  height:50vh;
}

.fixed-card{
  min-height: 90vh;
}
</style>
